// @flow
// wrapper for aura-icons
import * as React from 'react';
import styled from 'styled-components';

import { propsColor } from './IconButton';

type IconName =
  | '500px'
  | 'accessible-icon'
  | 'accusoft'
  | 'acquisitions-incorporated'
  | 'ad'
  | 'address-book'
  | 'address-card'
  | 'adjust'
  | 'adn'
  | 'adobe'
  | 'adversal'
  | 'affiliatetheme'
  | 'air-freshener'
  | 'airbnb'
  | 'algolia'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'alipay'
  | 'allergies'
  | 'amazon'
  | 'amazon-pay'
  | 'ambulance'
  | 'american-sign-language-interpreting'
  | 'amilia'
  | 'anchor'
  | 'android'
  | 'angellist'
  | 'angle-double-down'
  | 'angle-double-left'
  | 'angle-double-right'
  | 'angle-double-up'
  | 'angle-down'
  | 'angle-left'
  | 'angle-right'
  | 'angle-up'
  | 'angry'
  | 'angrycreative'
  | 'angular'
  | 'ankh'
  | 'app-store'
  | 'app-store-ios'
  | 'apper'
  | 'apple'
  | 'apple-alt'
  | 'apple-pay'
  | 'archive'
  | 'archway'
  | 'arrow-alt-circle-down'
  | 'arrow-alt-circle-left'
  | 'arrow-alt-circle-right'
  | 'arrow-alt-circle-up'
  | 'arrow-circle-down'
  | 'arrow-circle-left'
  | 'arrow-circle-right'
  | 'arrow-circle-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'arrows-alt'
  | 'arrows-alt-h'
  | 'arrows-alt-v'
  | 'artstation'
  | 'assistive-listening-systems'
  | 'asterisk'
  | 'asymmetrik'
  | 'at'
  | 'atlas'
  | 'atlassian'
  | 'atom'
  | 'audible'
  | 'audio-description'
  | 'autoprefixer'
  | 'avianex'
  | 'aviato'
  | 'award'
  | 'aws'
  | 'baby'
  | 'baby-carriage'
  | 'backspace'
  | 'backward'
  | 'bacon'
  | 'bahai'
  | 'balance-scale'
  | 'balance-scale-left'
  | 'balance-scale-right'
  | 'ban'
  | 'band-aid'
  | 'bandcamp'
  | 'barcode'
  | 'bars'
  | 'baseball-ball'
  | 'basketball-ball'
  | 'bath'
  | 'battery-empty'
  | 'battery-full'
  | 'battery-half'
  | 'battery-quarter'
  | 'battery-three-quarters'
  | 'battle-net'
  | 'bed'
  | 'beer'
  | 'behance'
  | 'behance-square'
  | 'bell'
  | 'bell-slash'
  | 'bezier-curve'
  | 'bible'
  | 'bicycle'
  | 'biking'
  | 'bimobject'
  | 'binoculars'
  | 'biohazard'
  | 'birthday-cake'
  | 'bitbucket'
  | 'bitcoin'
  | 'bity'
  | 'black-tie'
  | 'blackberry'
  | 'blender'
  | 'blender-phone'
  | 'blind'
  | 'blog'
  | 'blogger'
  | 'blogger-b'
  | 'bluetooth'
  | 'bluetooth-b'
  | 'bold'
  | 'bolt'
  | 'bomb'
  | 'bone'
  | 'bong'
  | 'book'
  | 'book-dead'
  | 'book-medical'
  | 'book-open'
  | 'book-reader'
  | 'bookmark'
  | 'bootstrap'
  | 'border-all'
  | 'border-none'
  | 'border-style'
  | 'bowling-ball'
  | 'box'
  | 'box-open'
  | 'boxes'
  | 'braille'
  | 'brain'
  | 'bread-slice'
  | 'briefcase'
  | 'briefcase-medical'
  | 'broadcast-tower'
  | 'broom'
  | 'brush'
  | 'btc'
  | 'buffer'
  | 'bug'
  | 'building'
  | 'bullhorn'
  | 'bullseye'
  | 'burn'
  | 'buromobelexperte'
  | 'bus'
  | 'bus-alt'
  | 'business-time'
  | 'buy-n-large'
  | 'buysellads'
  | 'calculator'
  | 'calendar'
  | 'calendar-alt'
  | 'calendar-check'
  | 'calendar-day'
  | 'calendar-minus'
  | 'calendar-plus'
  | 'calendar-times'
  | 'calendar-week'
  | 'camera'
  | 'camera-retro'
  | 'campground'
  | 'canadian-maple-leaf'
  | 'candy-cane'
  | 'cannabis'
  | 'capsules'
  | 'car'
  | 'car-alt'
  | 'car-battery'
  | 'car-crash'
  | 'car-side'
  | 'caravan'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-square-down'
  | 'caret-square-left'
  | 'caret-square-right'
  | 'caret-square-up'
  | 'caret-up'
  | 'carrot'
  | 'cart-arrow-down'
  | 'cart-plus'
  | 'cash-register'
  | 'cat'
  | 'cc-amazon-pay'
  | 'cc-amex'
  | 'cc-apple-pay'
  | 'cc-diners-club'
  | 'cc-discover'
  | 'cc-jcb'
  | 'cc-mastercard'
  | 'cc-paypal'
  | 'cc-stripe'
  | 'cc-visa'
  | 'centercode'
  | 'centos'
  | 'certificate'
  | 'chair'
  | 'chalkboard'
  | 'chalkboard-teacher'
  | 'charging-station'
  | 'chart-area'
  | 'chart-bar'
  | 'chart-line'
  | 'chart-pie'
  | 'check'
  | 'check-circle'
  | 'check-double'
  | 'check-square'
  | 'cheese'
  | 'chess'
  | 'chess-bishop'
  | 'chess-board'
  | 'chess-king'
  | 'chess-knight'
  | 'chess-pawn'
  | 'chess-queen'
  | 'chess-rook'
  | 'chevron-circle-down'
  | 'chevron-circle-left'
  | 'chevron-circle-right'
  | 'chevron-circle-up'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'child'
  | 'chrome'
  | 'chromecast'
  | 'church'
  | 'circle'
  | 'circle-notch'
  | 'city'
  | 'clinic-medical'
  | 'clipboard'
  | 'clipboard-check'
  | 'clipboard-list'
  | 'clock'
  | 'clone'
  | 'closed-captioning'
  | 'cloud'
  | 'cloud-download-alt'
  | 'cloud-meatball'
  | 'cloud-moon'
  | 'cloud-moon-rain'
  | 'cloud-rain'
  | 'cloud-showers-heavy'
  | 'cloud-sun'
  | 'cloud-sun-rain'
  | 'cloud-upload-alt'
  | 'cloudscale'
  | 'cloudsmith'
  | 'cloudversify'
  | 'cocktail'
  | 'code'
  | 'code-branch'
  | 'codepen'
  | 'codiepie'
  | 'coffee'
  | 'cog'
  | 'cogs'
  | 'coins'
  | 'columns'
  | 'comment'
  | 'comment-alt'
  | 'comment-dollar'
  | 'comment-dots'
  | 'comment-medical'
  | 'comment-slash'
  | 'comments'
  | 'comments-dollar'
  | 'compact-disc'
  | 'compass'
  | 'compress'
  | 'compress-alt'
  | 'compress-arrows-alt'
  | 'concierge-bell'
  | 'confluence'
  | 'connectdevelop'
  | 'contao'
  | 'cookie'
  | 'cookie-bite'
  | 'copy'
  | 'copyright'
  | 'cotton-bureau'
  | 'couch'
  | 'cpanel'
  | 'creative-commons'
  | 'creative-commons-by'
  | 'creative-commons-nc'
  | 'creative-commons-nc-eu'
  | 'creative-commons-nc-jp'
  | 'creative-commons-nd'
  | 'creative-commons-pd'
  | 'creative-commons-pd-alt'
  | 'creative-commons-remix'
  | 'creative-commons-sa'
  | 'creative-commons-sampling'
  | 'creative-commons-sampling-plus'
  | 'creative-commons-share'
  | 'creative-commons-zero'
  | 'credit-card'
  | 'critical-role'
  | 'crop'
  | 'crop-alt'
  | 'cross'
  | 'crosshairs'
  | 'crow'
  | 'crown'
  | 'crutch'
  | 'css3'
  | 'css3-alt'
  | 'cube'
  | 'cubes'
  | 'cut'
  | 'cuttlefish'
  | 'd-and-d'
  | 'd-and-d-beyond'
  | 'dashcube'
  | 'database'
  | 'deaf'
  | 'delicious'
  | 'democrat'
  | 'deploydog'
  | 'deskpro'
  | 'desktop'
  | 'dev'
  | 'deviantart'
  | 'dharmachakra'
  | 'dhl'
  | 'diagnoses'
  | 'diaspora'
  | 'dice'
  | 'dice-d20'
  | 'dice-d6'
  | 'dice-five'
  | 'dice-four'
  | 'dice-one'
  | 'dice-six'
  | 'dice-three'
  | 'dice-two'
  | 'digg'
  | 'digital-ocean'
  | 'digital-tachograph'
  | 'directions'
  | 'discord'
  | 'discourse'
  | 'divide'
  | 'dizzy'
  | 'dna'
  | 'dochub'
  | 'docker'
  | 'dog'
  | 'dollar-sign'
  | 'dolly'
  | 'dolly-flatbed'
  | 'donate'
  | 'door-closed'
  | 'door-open'
  | 'dot-circle'
  | 'dove'
  | 'download'
  | 'draft2digital'
  | 'drafting-compass'
  | 'dragon'
  | 'draw-polygon'
  | 'dribbble'
  | 'dribbble-square'
  | 'dropbox'
  | 'drum'
  | 'drum-steelpan'
  | 'drumstick-bite'
  | 'drupal'
  | 'dumbbell'
  | 'dumpster'
  | 'dumpster-fire'
  | 'dungeon'
  | 'dyalog'
  | 'earlybirds'
  | 'ebay'
  | 'edge'
  | 'edit'
  | 'egg'
  | 'eject'
  | 'elementor'
  | 'ellipsis-h'
  | 'ellipsis-v'
  | 'ello'
  | 'ember'
  | 'empire'
  | 'envelope'
  | 'envelope-open'
  | 'envelope-open-text'
  | 'envelope-square'
  | 'envira'
  | 'equals'
  | 'eraser'
  | 'erlang'
  | 'ethereum'
  | 'ethernet'
  | 'etsy'
  | 'euro-sign'
  | 'evernote'
  | 'exchange-alt'
  | 'exclamation'
  | 'exclamation-circle'
  | 'exclamation-triangle'
  | 'expand'
  | 'expand-alt'
  | 'expand-arrows-alt'
  | 'expeditedssl'
  | 'external-link-alt'
  | 'external-link-square-alt'
  | 'eye'
  | 'eye-dropper'
  | 'eye-slash'
  | 'facebook'
  | 'facebook-f'
  | 'facebook-messenger'
  | 'facebook-square'
  | 'fan'
  | 'fantasy-flight-games'
  | 'fast-backward'
  | 'fast-forward'
  | 'fax'
  | 'feather'
  | 'feather-alt'
  | 'fedex'
  | 'fedora'
  | 'female'
  | 'fighter-jet'
  | 'figma'
  | 'file'
  | 'file-alt'
  | 'file-archive'
  | 'file-audio'
  | 'file-code'
  | 'file-contract'
  | 'file-csv'
  | 'file-download'
  | 'file-excel'
  | 'file-export'
  | 'file-image'
  | 'file-import'
  | 'file-invoice'
  | 'file-invoice-dollar'
  | 'file-medical'
  | 'file-medical-alt'
  | 'file-pdf'
  | 'file-powerpoint'
  | 'file-prescription'
  | 'file-signature'
  | 'file-upload'
  | 'file-video'
  | 'file-word'
  | 'fill'
  | 'fill-drip'
  | 'film'
  | 'filter'
  | 'fingerprint'
  | 'fire'
  | 'fire-alt'
  | 'fire-extinguisher'
  | 'firefox'
  | 'firefox-browser'
  | 'first-aid'
  | 'first-order'
  | 'first-order-alt'
  | 'firstdraft'
  | 'fish'
  | 'fist-raised'
  | 'flag'
  | 'flag-checkered'
  | 'flag-usa'
  | 'flask'
  | 'flickr'
  | 'flipboard'
  | 'flushed'
  | 'fly'
  | 'folder'
  | 'folder-minus'
  | 'folder-open'
  | 'folder-plus'
  | 'font'
  | 'font-awesome'
  | 'font-awesome-alt'
  | 'font-awesome-flag'
  | 'font-awesome-logo-full'
  | 'fonticons'
  | 'fonticons-fi'
  | 'football-ball'
  | 'fort-awesome'
  | 'fort-awesome-alt'
  | 'forumbee'
  | 'forward'
  | 'foursquare'
  | 'free-code-camp'
  | 'freebsd'
  | 'frog'
  | 'frown'
  | 'frown-open'
  | 'fulcrum'
  | 'funnel-dollar'
  | 'futbol'
  | 'galactic-republic'
  | 'galactic-senate'
  | 'gamepad'
  | 'gas-pump'
  | 'gavel'
  | 'gem'
  | 'genderless'
  | 'get-pocket'
  | 'gg'
  | 'gg-circle'
  | 'ghost'
  | 'gift'
  | 'gifts'
  | 'git'
  | 'git-alt'
  | 'git-square'
  | 'github'
  | 'github-alt'
  | 'github-square'
  | 'gitkraken'
  | 'gitlab'
  | 'gitter'
  | 'glass-cheers'
  | 'glass-martini'
  | 'glass-martini-alt'
  | 'glass-whiskey'
  | 'glasses'
  | 'glide'
  | 'glide-g'
  | 'globe'
  | 'globe-africa'
  | 'globe-americas'
  | 'globe-asia'
  | 'globe-europe'
  | 'gofore'
  | 'golf-ball'
  | 'goodreads'
  | 'goodreads-g'
  | 'google'
  | 'google-drive'
  | 'google-play'
  | 'google-plus'
  | 'google-plus-g'
  | 'google-plus-square'
  | 'google-wallet'
  | 'gopuram'
  | 'graduation-cap'
  | 'gratipay'
  | 'grav'
  | 'greater-than'
  | 'greater-than-equal'
  | 'grimace'
  | 'grin'
  | 'grin-alt'
  | 'grin-beam'
  | 'grin-beam-sweat'
  | 'grin-hearts'
  | 'grin-squint'
  | 'grin-squint-tears'
  | 'grin-stars'
  | 'grin-tears'
  | 'grin-tongue'
  | 'grin-tongue-squint'
  | 'grin-tongue-wink'
  | 'grin-wink'
  | 'grip-horizontal'
  | 'grip-lines'
  | 'grip-lines-vertical'
  | 'grip-vertical'
  | 'gripfire'
  | 'grunt'
  | 'guitar'
  | 'gulp'
  | 'h-square'
  | 'hacker-news'
  | 'hacker-news-square'
  | 'hackerrank'
  | 'hamburger'
  | 'hammer'
  | 'hamsa'
  | 'hand-holding'
  | 'hand-holding-heart'
  | 'hand-holding-usd'
  | 'hand-lizard'
  | 'hand-middle-finger'
  | 'hand-paper'
  | 'hand-peace'
  | 'hand-point-down'
  | 'hand-point-left'
  | 'hand-point-right'
  | 'hand-point-up'
  | 'hand-pointer'
  | 'hand-rock'
  | 'hand-scissors'
  | 'hand-spock'
  | 'hands'
  | 'hands-helping'
  | 'handshake'
  | 'hanukiah'
  | 'hard-hat'
  | 'hashtag'
  | 'hat-cowboy'
  | 'hat-cowboy-side'
  | 'hat-wizard'
  | 'hdd'
  | 'heading'
  | 'headphones'
  | 'headphones-alt'
  | 'headset'
  | 'heart'
  | 'heart-broken'
  | 'heartbeat'
  | 'helicopter'
  | 'highlighter'
  | 'hiking'
  | 'hippo'
  | 'hips'
  | 'hire-a-helper'
  | 'history'
  | 'hockey-puck'
  | 'holly-berry'
  | 'home'
  | 'hooli'
  | 'hornbill'
  | 'horse'
  | 'horse-head'
  | 'hospital'
  | 'hospital-alt'
  | 'hospital-symbol'
  | 'hot-tub'
  | 'hotdog'
  | 'hotel'
  | 'hotjar'
  | 'hourglass'
  | 'hourglass-end'
  | 'hourglass-half'
  | 'hourglass-start'
  | 'house-damage'
  | 'houzz'
  | 'hryvnia'
  | 'html5'
  | 'hubspot'
  | 'i-cursor'
  | 'ice-cream'
  | 'icicles'
  | 'icons'
  | 'id-badge'
  | 'id-card'
  | 'id-card-alt'
  | 'ideal'
  | 'igloo'
  | 'image'
  | 'images'
  | 'imdb'
  | 'inbox'
  | 'indent'
  | 'industry'
  | 'infinity'
  | 'info'
  | 'info-circle'
  | 'instagram'
  | 'intercom'
  | 'internet-explorer'
  | 'invision'
  | 'ioxhost'
  | 'italic'
  | 'itch-io'
  | 'itunes'
  | 'itunes-note'
  | 'java'
  | 'jedi'
  | 'jedi-order'
  | 'jenkins'
  | 'jira'
  | 'joget'
  | 'joint'
  | 'joomla'
  | 'journal-whills'
  | 'js'
  | 'js-square'
  | 'jsfiddle'
  | 'kaaba'
  | 'kaggle'
  | 'key'
  | 'keybase'
  | 'keyboard'
  | 'keycdn'
  | 'khanda'
  | 'kickstarter'
  | 'kickstarter-k'
  | 'kiss'
  | 'kiss-beam'
  | 'kiss-wink-heart'
  | 'kiwi-bird'
  | 'korvue'
  | 'landmark'
  | 'language'
  | 'laptop'
  | 'laptop-code'
  | 'laptop-medical'
  | 'laravel'
  | 'lastfm'
  | 'lastfm-square'
  | 'laugh'
  | 'laugh-beam'
  | 'laugh-squint'
  | 'laugh-wink'
  | 'layer-group'
  | 'leaf'
  | 'leanpub'
  | 'lemon'
  | 'less'
  | 'less-than'
  | 'less-than-equal'
  | 'level-down-alt'
  | 'level-up-alt'
  | 'life-ring'
  | 'lightbulb'
  | 'line'
  | 'link'
  | 'linkedin'
  | 'linkedin-in'
  | 'linode'
  | 'linux'
  | 'lira-sign'
  | 'list'
  | 'list-alt'
  | 'list-ol'
  | 'list-ul'
  | 'location-arrow'
  | 'lock'
  | 'lock-open'
  | 'long-arrow-alt-down'
  | 'long-arrow-alt-left'
  | 'long-arrow-alt-right'
  | 'long-arrow-alt-up'
  | 'low-vision'
  | 'luggage-cart'
  | 'lyft'
  | 'magento'
  | 'magic'
  | 'magnet'
  | 'mail-bulk'
  | 'mailchimp'
  | 'male'
  | 'mandalorian'
  | 'map'
  | 'map-marked'
  | 'map-marked-alt'
  | 'map-marker'
  | 'map-marker-alt'
  | 'map-pin'
  | 'map-signs'
  | 'markdown'
  | 'marker'
  | 'mars'
  | 'mars-double'
  | 'mars-stroke'
  | 'mars-stroke-h'
  | 'mars-stroke-v'
  | 'mask'
  | 'mastodon'
  | 'maxcdn'
  | 'mdb'
  | 'medal'
  | 'medapps'
  | 'medium'
  | 'medium-m'
  | 'medkit'
  | 'medrt'
  | 'meetup'
  | 'megaport'
  | 'meh'
  | 'meh-blank'
  | 'meh-rolling-eyes'
  | 'memory'
  | 'mendeley'
  | 'menorah'
  | 'mercury'
  | 'meteor'
  | 'microblog'
  | 'microchip'
  | 'microphone'
  | 'microphone-alt'
  | 'microphone-alt-slash'
  | 'microphone-slash'
  | 'microscope'
  | 'microsoft'
  | 'minus'
  | 'minus-circle'
  | 'minus-square'
  | 'mitten'
  | 'mix'
  | 'mixcloud'
  | 'mizuni'
  | 'mobile'
  | 'mobile-alt'
  | 'modx'
  | 'monero'
  | 'money-bill'
  | 'money-bill-alt'
  | 'money-bill-wave'
  | 'money-bill-wave-alt'
  | 'money-check'
  | 'money-check-alt'
  | 'monument'
  | 'moon'
  | 'mortar-pestle'
  | 'mosque'
  | 'motorcycle'
  | 'mountain'
  | 'mouse'
  | 'mouse-pointer'
  | 'mug-hot'
  | 'music'
  | 'napster'
  | 'neos'
  | 'network-wired'
  | 'neuter'
  | 'newspaper'
  | 'nimblr'
  | 'node'
  | 'node-js'
  | 'not-equal'
  | 'notes-medical'
  | 'npm'
  | 'ns8'
  | 'nutritionix'
  | 'object-group'
  | 'object-ungroup'
  | 'odnoklassniki'
  | 'odnoklassniki-square'
  | 'oil-can'
  | 'old-republic'
  | 'om'
  | 'opencart'
  | 'openid'
  | 'opera'
  | 'optin-monster'
  | 'orcid'
  | 'osi'
  | 'otter'
  | 'outdent'
  | 'page4'
  | 'pagelines'
  | 'pager'
  | 'paint-brush'
  | 'paint-roller'
  | 'palette'
  | 'palfed'
  | 'pallet'
  | 'paper-plane'
  | 'paperclip'
  | 'parachute-box'
  | 'paragraph'
  | 'parking'
  | 'passport'
  | 'pastafarianism'
  | 'paste'
  | 'patreon'
  | 'pause'
  | 'pause-circle'
  | 'paw'
  | 'paypal'
  | 'peace'
  | 'pen'
  | 'pen-alt'
  | 'pen-fancy'
  | 'pen-nib'
  | 'pen-square'
  | 'pencil-alt'
  | 'pencil-ruler'
  | 'penny-arcade'
  | 'people-carry'
  | 'pepper-hot'
  | 'percent'
  | 'percentage'
  | 'periscope'
  | 'person-booth'
  | 'phabricator'
  | 'phoenix-framework'
  | 'phoenix-squadron'
  | 'phone'
  | 'phone-alt'
  | 'phone-slash'
  | 'phone-square'
  | 'phone-square-alt'
  | 'phone-volume'
  | 'photo-video'
  | 'php'
  | 'pied-piper'
  | 'pied-piper-alt'
  | 'pied-piper-hat'
  | 'pied-piper-pp'
  | 'pied-piper-square'
  | 'piggy-bank'
  | 'pills'
  | 'pinterest'
  | 'pinterest-p'
  | 'pinterest-square'
  | 'pizza-slice'
  | 'place-of-worship'
  | 'plane'
  | 'plane-arrival'
  | 'plane-departure'
  | 'play'
  | 'play-circle'
  | 'playstation'
  | 'plug'
  | 'plus'
  | 'plus-circle'
  | 'plus-square'
  | 'podcast'
  | 'poll'
  | 'poll-h'
  | 'poo'
  | 'poo-storm'
  | 'poop'
  | 'portrait'
  | 'pound-sign'
  | 'power-off'
  | 'pray'
  | 'praying-hands'
  | 'prescription'
  | 'prescription-bottle'
  | 'prescription-bottle-alt'
  | 'print'
  | 'procedures'
  | 'product-hunt'
  | 'project-diagram'
  | 'pushed'
  | 'puzzle-piece'
  | 'python'
  | 'qq'
  | 'qrcode'
  | 'question'
  | 'question-circle'
  | 'quidditch'
  | 'quinscape'
  | 'quora'
  | 'quote-left'
  | 'quote-right'
  | 'quran'
  | 'r-project'
  | 'radiation'
  | 'radiation-alt'
  | 'rainbow'
  | 'random'
  | 'raspberry-pi'
  | 'ravelry'
  | 'react'
  | 'reacteurope'
  | 'readme'
  | 'rebel'
  | 'receipt'
  | 'record-vinyl'
  | 'recycle'
  | 'red-river'
  | 'reddit'
  | 'reddit-alien'
  | 'reddit-square'
  | 'redhat'
  | 'redo'
  | 'redo-alt'
  | 'registered'
  | 'remove-format'
  | 'renren'
  | 'reply'
  | 'reply-all'
  | 'replyd'
  | 'republican'
  | 'researchgate'
  | 'resolving'
  | 'restroom'
  | 'retweet'
  | 'rev'
  | 'ribbon'
  | 'ring'
  | 'road'
  | 'robot'
  | 'rocket'
  | 'rocketchat'
  | 'rockrms'
  | 'route'
  | 'rss'
  | 'rss-square'
  | 'ruble-sign'
  | 'ruler'
  | 'ruler-combined'
  | 'ruler-horizontal'
  | 'ruler-vertical'
  | 'running'
  | 'rupee-sign'
  | 'sad-cry'
  | 'sad-tear'
  | 'safari'
  | 'salesforce'
  | 'sass'
  | 'satellite'
  | 'satellite-dish'
  | 'save'
  | 'schlix'
  | 'school'
  | 'screwdriver'
  | 'scribd'
  | 'scroll'
  | 'sd-card'
  | 'search'
  | 'search-dollar'
  | 'search-location'
  | 'search-minus'
  | 'search-plus'
  | 'searchengin'
  | 'seedling'
  | 'sellcast'
  | 'sellsy'
  | 'server'
  | 'servicestack'
  | 'shapes'
  | 'share'
  | 'share-alt'
  | 'share-alt-square'
  | 'share-square'
  | 'shekel-sign'
  | 'shield-alt'
  | 'ship'
  | 'shipping-fast'
  | 'shirtsinbulk'
  | 'shoe-prints'
  | 'shopping-bag'
  | 'shopping-basket'
  | 'shopping-cart'
  | 'shopware'
  | 'shower'
  | 'shuttle-van'
  | 'sign'
  | 'sign-in-alt'
  | 'sign-language'
  | 'sign-out-alt'
  | 'signal'
  | 'signature'
  | 'sim-card'
  | 'simplybuilt'
  | 'sistrix'
  | 'sitemap'
  | 'sith'
  | 'skating'
  | 'sketch'
  | 'skiing'
  | 'skiing-nordic'
  | 'skull'
  | 'skull-crossbones'
  | 'skyatlas'
  | 'skype'
  | 'slack'
  | 'slack-hash'
  | 'slash'
  | 'sleigh'
  | 'sliders-h'
  | 'slideshare'
  | 'smile'
  | 'smile-beam'
  | 'smile-wink'
  | 'smog'
  | 'smoking'
  | 'smoking-ban'
  | 'sms'
  | 'snapchat'
  | 'snapchat-ghost'
  | 'snapchat-square'
  | 'snowboarding'
  | 'snowflake'
  | 'snowman'
  | 'snowplow'
  | 'socks'
  | 'solar-panel'
  | 'sort'
  | 'sort-alpha-down'
  | 'sort-alpha-down-alt'
  | 'sort-alpha-up'
  | 'sort-alpha-up-alt'
  | 'sort-amount-down'
  | 'sort-amount-down-alt'
  | 'sort-amount-up'
  | 'sort-amount-up-alt'
  | 'sort-down'
  | 'sort-numeric-down'
  | 'sort-numeric-down-alt'
  | 'sort-numeric-up'
  | 'sort-numeric-up-alt'
  | 'sort-up'
  | 'soundcloud'
  | 'sourcetree'
  | 'spa'
  | 'space-shuttle'
  | 'speakap'
  | 'speaker-deck'
  | 'spell-check'
  | 'spider'
  | 'spinner'
  | 'splotch'
  | 'spotify'
  | 'spray-can'
  | 'square'
  | 'square-full'
  | 'square-root-alt'
  | 'squarespace'
  | 'stack-exchange'
  | 'stack-overflow'
  | 'stackpath'
  | 'stamp'
  | 'star'
  | 'star-and-crescent'
  | 'star-half'
  | 'star-half-alt'
  | 'star-of-david'
  | 'star-of-life'
  | 'staylinked'
  | 'steam'
  | 'steam-square'
  | 'steam-symbol'
  | 'step-backward'
  | 'step-forward'
  | 'stethoscope'
  | 'sticker-mule'
  | 'sticky-note'
  | 'stop'
  | 'stop-circle'
  | 'stopwatch'
  | 'store'
  | 'store-alt'
  | 'strava'
  | 'stream'
  | 'street-view'
  | 'strikethrough'
  | 'stripe'
  | 'stripe-s'
  | 'stroopwafel'
  | 'studiovinari'
  | 'stumbleupon'
  | 'stumbleupon-circle'
  | 'subscript'
  | 'subway'
  | 'suitcase'
  | 'suitcase-rolling'
  | 'sun'
  | 'superpowers'
  | 'superscript'
  | 'supple'
  | 'surprise'
  | 'suse'
  | 'swatchbook'
  | 'swift'
  | 'swimmer'
  | 'swimming-pool'
  | 'symfony'
  | 'synagogue'
  | 'sync'
  | 'sync-alt'
  | 'syringe'
  | 'table'
  | 'table-tennis'
  | 'tablet'
  | 'tablet-alt'
  | 'tablets'
  | 'tachometer-alt'
  | 'tag'
  | 'tags'
  | 'tape'
  | 'tasks'
  | 'taxi'
  | 'teamspeak'
  | 'teeth'
  | 'teeth-open'
  | 'telegram'
  | 'telegram-plane'
  | 'temperature-high'
  | 'temperature-low'
  | 'tencent-weibo'
  | 'tenge'
  | 'terminal'
  | 'text-height'
  | 'text-width'
  | 'th'
  | 'th-large'
  | 'th-list'
  | 'the-red-yeti'
  | 'theater-masks'
  | 'themeco'
  | 'themeisle'
  | 'thermometer'
  | 'thermometer-empty'
  | 'thermometer-full'
  | 'thermometer-half'
  | 'thermometer-quarter'
  | 'thermometer-three-quarters'
  | 'think-peaks'
  | 'thumbs-down'
  | 'thumbs-up'
  | 'thumbtack'
  | 'ticket-alt'
  | 'times'
  | 'times-circle'
  | 'tint'
  | 'tint-slash'
  | 'tired'
  | 'toggle-off'
  | 'toggle-on'
  | 'toilet'
  | 'toilet-paper'
  | 'toolbox'
  | 'tools'
  | 'tooth'
  | 'torah'
  | 'torii-gate'
  | 'tractor'
  | 'trade-federation'
  | 'trademark'
  | 'traffic-light'
  | 'trailer'
  | 'train'
  | 'tram'
  | 'transgender'
  | 'transgender-alt'
  | 'trash'
  | 'trash-alt'
  | 'trash-restore'
  | 'trash-restore-alt'
  | 'tree'
  | 'trello'
  | 'tripadvisor'
  | 'trophy'
  | 'truck'
  | 'truck-loading'
  | 'truck-monster'
  | 'truck-moving'
  | 'truck-pickup'
  | 'tshirt'
  | 'tty'
  | 'tumblr'
  | 'tumblr-square'
  | 'tv'
  | 'twitch'
  | 'twitter'
  | 'twitter-square'
  | 'typo3'
  | 'uber'
  | 'ubuntu'
  | 'uikit'
  | 'umbraco'
  | 'umbrella'
  | 'umbrella-beach'
  | 'underline'
  | 'undo'
  | 'undo-alt'
  | 'uniregistry'
  | 'unity'
  | 'universal-access'
  | 'university'
  | 'unlink'
  | 'unlock'
  | 'unlock-alt'
  | 'untappd'
  | 'upload'
  | 'ups'
  | 'usb'
  | 'user'
  | 'user-alt'
  | 'user-alt-slash'
  | 'user-astronaut'
  | 'user-check'
  | 'user-circle'
  | 'user-clock'
  | 'user-cog'
  | 'user-edit'
  | 'user-friends'
  | 'user-graduate'
  | 'user-injured'
  | 'user-lock'
  | 'user-md'
  | 'user-minus'
  | 'user-ninja'
  | 'user-nurse'
  | 'user-plus'
  | 'user-secret'
  | 'user-shield'
  | 'user-slash'
  | 'user-tag'
  | 'user-tie'
  | 'user-times'
  | 'users'
  | 'users-cog'
  | 'usps'
  | 'ussunnah'
  | 'utensil-spoon'
  | 'utensils'
  | 'vaadin'
  | 'vector-square'
  | 'venus'
  | 'venus-double'
  | 'venus-mars'
  | 'viacoin'
  | 'viadeo'
  | 'viadeo-square'
  | 'vial'
  | 'vials'
  | 'viber'
  | 'video'
  | 'video-slash'
  | 'vihara'
  | 'vimeo'
  | 'vimeo-square'
  | 'vimeo-v'
  | 'vine'
  | 'vk'
  | 'vnv'
  | 'voicemail'
  | 'volleyball-ball'
  | 'volume-down'
  | 'volume-mute'
  | 'volume-off'
  | 'volume-up'
  | 'vote-yea'
  | 'vr-cardboard'
  | 'vuejs'
  | 'walking'
  | 'wallet'
  | 'warehouse'
  | 'water'
  | 'wave-square'
  | 'waze'
  | 'weebly'
  | 'weibo'
  | 'weight'
  | 'weight-hanging'
  | 'weixin'
  | 'whatsapp'
  | 'whatsapp-square'
  | 'wheelchair'
  | 'whmcs'
  | 'wifi'
  | 'wikipedia-w'
  | 'wind'
  | 'window-close'
  | 'window-maximize'
  | 'window-minimize'
  | 'window-restore'
  | 'windows'
  | 'wine-bottle'
  | 'wine-glass'
  | 'wine-glass-alt'
  | 'wix'
  | 'wizards-of-the-coast'
  | 'wolf-pack-battalion'
  | 'won-sign'
  | 'wordpress'
  | 'wordpress-simple'
  | 'wpbeginner'
  | 'wpexplorer'
  | 'wpforms'
  | 'wpressr'
  | 'wrench'
  | 'x-ray'
  | 'xbox'
  | 'xing'
  | 'xing-square'
  | 'y-combinator'
  | 'yahoo'
  | 'yammer'
  | 'yandex'
  | 'yandex-international'
  | 'yarn'
  | 'yelp'
  | 'yen-sign'
  | 'yin-yang'
  | 'yoast'
  | 'youtube'
  | 'youtube-square'
  | 'zhihu';

type TextStyle = 'default' | 'primary' | 'success' | 'info' | 'warning' | 'danger';

type IconClassProps = {
  name: IconName,
  li?: boolean,
  lg?: boolean,
  fw?: boolean,
  rotate90?: boolean,
  rotate180?: boolean,
  rotate270?: boolean,
  flipH?: boolean,
  flipV?: boolean,
  stack?: '1x' | '2x',
  bsStyle?: TextStyle,
  solid?: boolean,
  regular?: boolean,
};

type IconProps = {
  pad?: 'sm' | 'md' | 'lg' | number | string,
  smPad?: boolean,
  mdPad?: boolean,
  lgPad?: boolean,
  size?: number | string,
};

type Props = IconProps & IconClassProps;

const Icon: React.ComponentType<Props> = styled.i.attrs(
  ({
    name,
    li,
    lg,
    fw,
    rotate90,
    rotate180,
    rotate270,
    flipH,
    flipV,
    stack,
    bsStyle,
    solid,
    regular,
  }: IconClassProps) => ({
    className: [
      solid ? 'fas' : regular ? 'far' : 'fas',
      `fa-${name}`,
      li && 'fa-li',
      lg && 'fa-lg',
      fw && 'fa-fw',
      rotate90 && 'fa-rotate-90',
      rotate180 && 'fa-rotate-180',
      rotate270 && 'fa-rotate-270',
      flipH && 'fa-flip-horizontal',
      flipV && 'fa-flip-vertical',
      stack && `fa-stack-${stack}`,
      bsStyle && `text-${bsStyle}`,
    ]
      .filter((c) => c)
      .join(' '),
  }),
)`
  font-size: ${({ size }: IconProps) => (typeof size === 'number' ? `${size}px` : size)};
  color: ${(props) => propsColor(props)};
  padding: 0
    ${({ pad, smPad, mdPad, lgPad }: IconProps) =>
      pad === 'sm' && !!smPad
        ? '.25em'
        : pad === 'md' && !!mdPad
        ? '.40em'
        : pad === 'lg' && !!lgPad
        ? '.55em'
        : typeof pad === 'number'
        ? `${pad}px`
        : pad};
`;

export default Icon;

export const Spinner = styled(({ name = 'spinner', ...props }: Props) => (
  // $FlowFixMe
  <Icon name={name} {...props} />
))`
  animation: rotate 2s linear infinite;
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
